// in provider/mikizPicturesProvider.js

import {
    GET_LIST,
    GET_ONE,
    GET_MANY,
    GET_MANY_REFERENCE,
    CREATE,
    fetchUtils,
} from 'react-admin';
import { stringify } from 'query-string';

export default (apiUrl) => {
    const API_URL = apiUrl;
    const convertDataProviderRequestToHTTP = (type, resource, params) => {
        const options = {};
        const token = localStorage.getItem('token');
        options.headers = new Headers({ Accept: 'application/json' });
        options.headers.set('Authorization', `Bearer ${token}`);

        switch (type) {
            // FIXME
            case GET_LIST: {
                const { page, perPage } = params.pagination;
                const { field, order } = params.sort;
                
                const query = {
                    sort: field,
                    order: order,
                    page: page,
                    perPage: perPage,
                    //filter: JSON.stringify(params.filter),
                };
                return { 
                    url: `${API_URL}/${resource}?${stringify(query)}`,
                    options: options
                };
            }
            case GET_ONE:
                return { 
                    url: `${API_URL}/${resource}/?id=${params.id}`,
                    options: options 
                };
            case GET_MANY: {
                const query = {
                    filter: JSON.stringify({ id: params.ids }),
                };
                return { 
                    url: `${API_URL}/${resource}?${stringify(query)}`,
                    options: options
                };
            }
            case GET_MANY_REFERENCE: {
                //const { page, perPage } = params.pagination;
                //const { field, order } = params.sort;
                const query = {
                    sort: '',//JSON.stringify([field, order]),
                    range: '',//JSON.stringify([(page - 1) * perPage, (page * perPage) - 1]),
                    filter: ''//JSON.stringify({ ...params.filter, [params.target]: params.id }),
                };
                return { 
                    url: `${API_URL}/${resource}?${stringify(query)}`,
                    options: options
                };
            }
            default:
                throw new Error(`Unsupported fetch action type ${type}`);
            }
    }

    const convertHTTPResponseToDataProvider = (response, type, resource, params) => {
        const { headers, json } = response;
        switch (type) {
            case GET_MANY_REFERENCE:
            case GET_LIST:
                if (!headers.has('x-total-count')) {
                    let errMsg = 'The X-Total-Count header is missing in the HTTP Response. '
                    errMsg += 'If you are using CORS, did you declare X-Total-Count ' 
                    errMsg += 'in the Access-Control-Expose-Headers header?'
                    throw new Error(errMsg);
                }
                return {
                    data: json,
                    total: parseInt(headers
                        .get('x-total-count')
                        .split('/')
                        .pop(), 10),
                };
            case CREATE:
                return { data: { ...params.data, id: json.id } };
            default:
                return { data: json };
        }
    }

    return ((type, resource, params) => {
        const { fetchJson } = fetchUtils;
        const { url, options } = convertDataProviderRequestToHTTP(type, resource, params);
        return fetchJson(url, options)
            .then(response => convertHTTPResponseToDataProvider(response, type, resource, params));
    });
};
