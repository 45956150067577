// in src/App.js
import React from 'react';
import { Admin, Resource } from 'react-admin';
import mikizPicturesProvider from './providers/MikizPicturesProvider';
import { PictureList, PictureShow } from './Pictures';
import authProvider from './providers/AuthProvider';
import config from './config';
import LoginPage from './components/LoginPage';

const dataProvider = mikizPicturesProvider(config.backend.endpoint);

const App = () => (
  <Admin 
    dataProvider={dataProvider} 
    authProvider={authProvider}
    loginPage={LoginPage}>
    <Resource 
      name="pictures"
      list={PictureList}
      show={PictureShow} />
  </Admin>
);

export default App;
