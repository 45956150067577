
// in provider/authProvider.js

import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_CHECK } from 'react-admin';
import firebase from 'firebase/app';
import 'firebase/auth';
import config from '../config';

firebase.initializeApp(config.firebase);

export default (type, params) => {
    // called when the user attempts to log in
    if (type === AUTH_LOGIN) {
        const { username, password } = params

        return signin(username, password).then(() => {
            return authStateLogin();
        }).then(() => {
            const isAdmin = localStorage.getItem('admin');
            if (isAdmin === 'true') {
                return Promise.resolve();
            } else {
                return Promise.reject({ 'message': 'Non authorized!' });
            }
        }).catch(err => {
            return Promise.reject(err.message);
        });
    }

    // called when the user clicks on the logout button
    if (type === AUTH_LOGOUT) {
        firebase.auth().signOut().then(function() {
            localStorage.removeItem('token');
            localStorage.removeItem('admin');
            return Promise.resolve();
        }, (error) => {
            return Promise.reject(error);
        });
    }

    // called when the user navigates to a new location
    if (type === AUTH_CHECK) {
        const isAdmin = localStorage.getItem('admin');
        const user = firebase.auth().currentUser;
        
        getIdToken(user).then((idToken) => {
            return idToken && isAdmin === 'true'
            ? Promise.resolve()
            : Promise.reject();
        });
    }

    // called when the API returns an error
    if (type === AUTH_ERROR) {
        const { status, message } = params;
        if (status === 401 || status === 403 || status === 400) {
            localStorage.removeItem('token');
            localStorage.removeItem('admin');
            return Promise.reject(message);
        }
        return Promise.resolve();
    }
};

const getIdToken = (user) => new Promise((resolve, reject) => {
    if (user) {
        user.getIdToken().then(idToken => {
            // refreshing token
            localStorage.setItem('token', idToken);
            resolve(idToken);
        });
    } else {
        reject();
    }
});

const signin = (username, password) => new Promise((resolve, reject) => {
    firebase.auth().signInWithEmailAndPassword(username, password).then(() => {
        return resolve();
    }).catch(() => {
        return reject({ 'message': 'Invalid username or password' });
    });
});

const authStateLogin = () => new Promise((resolve, reject) => {
    firebase.auth().onAuthStateChanged((user, err) => {
        // Waiting to get the user
        if (user) {
            user.getIdTokenResult().then(result => {
                localStorage.setItem('token', result.token);
                localStorage.setItem('admin', result.claims.admin);
                return resolve();
            });
        }
        if (err) {
            return reject(err);
        }
    });
});
