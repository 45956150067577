// in src/KeyImageField.js

import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import classnames from 'classnames';

const styles = {
    list: {
        display: 'flex',
        listStyleType: 'none',
    },
    image: { 
        width: '10rem',
        height: '12rem', 
        maxWidth: '10rem', 
        maxHeight: '12rem',
        margin: '0.5rem',
        cursor: 'pointer'
    },
};

const OnImageClick = ((event) => {
    window.open(event, '_blank');
});


const KeyImageField = withStyles(styles)(({ className, classes = {},record = {}, source, title }) => {
    const sourceValue = record[source];
    const titleValue = record[title];

    if (Array.isArray(sourceValue)) {
        return (
            <ul className={classnames(classes.list, className)}>
                {sourceValue.map((file, index) => {
                    return (
                        <li key={index}>
                            <img
                                className={classes.image}
                                alt={titleValue}
                                title={titleValue}
                                src={file}
                                onClick={() => { OnImageClick(file) }}
                            />
                        </li>
                    )
                })}
            </ul>

        );
    }
    else {
        return (
            <div />
        );
    }
});

export default KeyImageField;
