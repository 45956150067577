// LoginPage.js
import React from 'react';
import { Login, LoginForm } from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';
import splash from '../images/splash-bg.jpg'; 

const styles = ({
    main: {
        background: `url(${splash})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
    }
    //icon: { display: 'none' },
});

const CustomLoginForm = withStyles({
    //button: { background: '#F15922' },
})(LoginForm);

const CustomLoginPage = props => (
    <Login
        loginForm={<CustomLoginForm />}
        {...props}
    />
);

export default withStyles(styles)(CustomLoginPage);
