// config.js
var firebase;
var backend;

const firebaseDev = {
    apiKey: "AIzaSyCnRzoj5UsDytm_1sRuBv4m6SXNi8FkXj8",
    authDomain: "mikiz-dev.firebaseapp.com",
    databaseURL: "https://mikiz-dev.firebaseio.com",
    projectId: "mikiz-dev",
    storageBucket: "mikiz-dev.appspot.com",
    messagingSenderId: "1001055022733"
};

const firebaseProd = {
    apiKey: "AIzaSyCptfEvtQvwTNAoiVzsnB2dPMidvoFidWU",
    authDomain: "mikiz-177622.firebaseapp.com",
    databaseURL: "https://mikiz-177622.firebaseio.com",
    projectId: "mikiz-177622",
    storageBucket: "mikiz-177622.appspot.com",
    messagingSenderId: "97734942911"
};

const backendDev = {
    //endpoint: "https://ab03d027.ngrok.io/v1/admin"
    endpoint: "https://mikiz-dev.firebaseapp.com/v1/admin"
}

const backendProd = {
    endpoint: "https://backend.mikiz.online/v1/admin"
    //endpoint: "http://f22d082e.ngrok.io/v1/admin"
}

if (process.env.REACT_APP_STAGE === 'production') {
    firebase = firebaseProd;
    backend = backendProd;
} else {
    firebase = firebaseDev;
    backend = backendDev;
}

export default { firebase, backend };
